<template>
  <div class="h-screen">
    <transition name="fade-right">
      <div
        v-if="showOverlay && !hideOverlay"
        class="fixed z-50 w-full h-full bg-white"
      >
        <img
          src="@/assets/images/concourse-view.svg"
          alt=""
          class="absolute right-20 bottom-32 w-1/3 max-h-full"
          data-aos="fade-in"
          data-aos-delay="100"
        />

        <div class="container mx-auto px-8">
          <div class="h-screen flex items-center py-24">
            <div class="relative z-50 w-1/2">
                <h1
                  class="mb-5 text-4xl xl:text-5xl 2xl:text-7xl font-light type-text"
                >
                  <div class="type-text__line">
                    Hello. Scroll left and right
                  </div>
                  <div class="type-text__line">
                    on the concourse to
                  </div>
                  <div class="type-text__line">
                    discover how <span class="font-bold text-blue">Global</span>
                  </div>
                  <div class="type-text__line">
                    <span class="font-bold text-blue">
                      Payments is bringing
                    </span>
                  </div>
                  <div class="type-text__line">
                    <span class="font-bold text-blue">
                      the game-day magic.
                    </span>
                  </div>
                </h1>

                <div
                  data-aos="fade-in"
                  data-aos-delay="1000"
                >
                  <button
                    class="inline-block border-2 border-blue
                            rounded-full py-2 lg:py-6 px-5 lg:px-12
                            bg-blue text-white font-bold
                            text-sm 2xl:text-xl
                            transition-colors
                            hover:bg-white hover:text-black"
                    @click="start"
                  >
                    Let’s go
                  </button>
                </div>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <div
      ref="slider"
      class="slider"
    >
      <div
        class="slider__img slider__img--short"
        ref="bgStadium"
      >
        <img
          src="@/assets/images/concourse-scene-bg-city-stadium.svg"
          alt=""
        />
      </div>

      <div
        class="slider__img slider__img--absolute"
        ref="bgDoors"
      >
        <img
          src="@/assets/images/concorse-scene-bg-doors.svg"
          alt=""
        />
      </div>

      <div
        class="slider__img slider__img--absolute"
        ref="bgPeople"
      >
        <img
          src="@/assets/images/concorse-scene-people-02.svg"
          alt=""
        />
      </div>

      <div
        class="absolute z-50 top-0 left-0 h-full"
        ref="bgHotspots"
      >
        <img
          src="@/assets/images/concorse-scene-bg-doors.svg"
          alt=""
          class="h-screen w-auto max-w-none invisible"
        />

        <v-hotspot-dot
          v-for="(hotspot, hotspotIndex) in hotspotsList"
          :key="hotspotIndex"
          :id="`hotspot${hotspotIndex}`"
          :hotspot="hotspot"
          class="absolute"
          :style="{
            top: `calc(100% * ${hotspot.position.y})`,
            left: `calc(100% * ${hotspot.position.x})`,
            transform: 'translate(-50%, -50%)',
          }"
          :animation-delay="hotspotIndex * 1.5"
          :animation-duration="hotspotIndex * 10"
        />
      </div>
    </div>

    <footer class="fixed z-40 left-0 bottom-3 w-full px-8 py-8">
      <div class="flex items-center justify-between">
        <div class="min-w-30vw">
          <router-link
            to="/stadium"
            class="group inline-flex items-center text-base 2xl:text-lg
                    cursor-pointer transition-colors hover:text-blue"
          >
            <i
              class="inline-flex items-center justify-center border-2 border-blue rounded-full
                    w-8 2xl:w-12 h-8 2xl:h-12 p-2 bg-blue
                    icon-arrow-left text-white mr-2
                    transition-colors
                    group-hover:bg-white group-hover:text-blue"
            />
            Back <span class="ml-1 text-blue font-bold">To Stadium</span>
          </router-link>
        </div>

        <button
          class="mx-3 border-2 border-blue rounded-full py-2 lg:py-6 px-5 lg:px-11
              bg-blue text-sm font-bold text-white 2xl:text-xl
                transition-colors
              hover:bg-white
                focus:outline-none focus:bg-white
                hover:text-black
                active:bg-white"
          @click="goToGetInTouch"
        >
          Contact us
        </button>

        <div class="min-w-30vw" />
      </div>
    </footer>

    <scroll-progress :scrolled="scrolled" />
</div>
</template>

<script>
import { mapGetters } from 'vuex';
import { getters } from '@/store';
import Scrollbar from 'smooth-scrollbar';
import SplitType from 'split-type';

import {
  ScrollProgress,
  HotspotDot,
} from '@/components';
import AOS from 'aos';

export default {
  name: 'ConcourseViewPage',
  components: {
    ScrollProgress,
    VHotspotDot: HotspotDot,
  },
  props: {
    hideOverlay: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showOverlay: true,
      solutionId: this.$route.params.solutionId,
      solutionsList: [],
      scrolled: 0,
    };
  },
  computed: {
    ...mapGetters([
      getters.content.hotspots,
      getters.content.solutions,
    ]),
    hotspotsList() {
      const coordinates = {
        // Food and beverage
        '5dpex6vc': {
          x: 0.3880,
          y: 0.4500,
        },
        // Retail
        gfiv3q2j: {
          x: 0.1030,
          y: 0.5000,
        },
        // Mobile purchasing
        wf9dqyyn: {
          x: 0.3000,
          y: 0.3000,
        },
        // In-suite catering
        ih49lslo: {
          x: 0.7580,
          y: 0.2500,
        },
        // Digital menu boards
        s66tnikc: {
          x: 0.6300,
          y: 0.4850,
        },
        // Self service
        jbyro0s7: {
          x: 0.2150,
          y: 0.6350,
        },
        // Back office
        ithasp3r: {
          x: 1.0150,
          y: 0.1800,
        },
        // Fan engagement
        yth3o42m: {
          x: 0.5250,
          y: 0.4450,
        },
      };

      const selectedMarkers = this.hotspots.markers.map((m) => ({
        ...m,
        position: coordinates[m.solution],
      }));

      const hotspots = selectedMarkers.map((m) => ({
        ...this.solutions.find((s) => s.uid === m.solution).media,
        ...m,
      }));
      return hotspots;
    },
  },
  mounted() {
    // eslint-disable-next-line no-new
    new SplitType(document.querySelector('.type-text'), {
      split: 'words, lines',
    });
    this.getSolutions();

    setTimeout(() => {
      AOS.init();
    }, 100);

    const {
      slider,
      bgPeople,
      bgDoors,
      bgHotspots,
    } = this.$refs;

    class HorizontalScrollPlugin extends Scrollbar.ScrollbarPlugin {
      static pluginName = 'horizontalScroll';

      // eslint-disable-next-line class-methods-use-this
      transformDelta(delta, fromEvent) {
        if (!/wheel/.test(fromEvent.type)) {
          return delta;
        }

        const { x, y } = delta;

        return {
          y: 0,
          x: Math.abs(x) > Math.abs(y) ? x : y,
        };
      }
    }

    Scrollbar.use(HorizontalScrollPlugin);

    const scrollbar = Scrollbar.init(slider, {
      alwaysShowTracks: false,
      damping: 0.05,
      continuousScrolling: false,
    });

    this.$nextTick(() => {
      if (!this.solutionId) return;
      scrollbar.update();
      const selectedHotspot = this.hotspotsList.find((h) => h.solution === this.solutionId);

      let scrollX;
      if (selectedHotspot.position.x > 1) {
        scrollX = scrollbar.limit.x * selectedHotspot.position.x - window.innerWidth / 1.5 || 0;
      } else {
        scrollX = scrollbar.limit.x * selectedHotspot.position.x - window.innerWidth / 2 || 0;
      }

      scrollbar.setPosition(scrollX, 0);
    });

    function setTranslate(xPos, yPos, el) {
      // eslint-disable-next-line no-param-reassign
      el.style.transform = `translate3d(${xPos}px, ${yPos}px, 0)`;
    }

    scrollbar.addListener((s) => {
      const xScrollPosition = s.offset.x;

      setTranslate(xScrollPosition * -0.05, 0, bgDoors);
      setTranslate(xScrollPosition * -0.2, 0, bgHotspots);
      setTranslate(xScrollPosition * -0.2, 0, bgPeople);

      this.scrolled = (s.offset.x / s.limit.x) * 100;
    });
  },
  methods: {
    start() {
      const delay = this.removeAnimations();

      setTimeout(() => {
        this.showOverlay = false;
      }, delay);
    },
    getSolutions() {
      const data = this.solutions;
      this.solutionsList = data.map((s, index) => ({ ...s, order: index }));
    },
    removeAnimations() {
      let maxDelay;
      const animElements = document.querySelectorAll('.aos-init');

      animElements.forEach((el, index, arr) => {
        maxDelay = Math.max(...[...arr].map((elem) => elem.dataset.aosDelay));

        const delay = maxDelay - el.dataset.aosDelay;
        setTimeout(() => {
          el.classList.remove('aos-animate');
        }, delay);
      });

      return maxDelay;
    },
    goToGetInTouch() {
      this.$router.push({
        name: 'get-in-touch.index',
        params: {
          hideOverlay: true,
        },
      });
    },
  },
};
</script>

<style lang="scss">
.slider {
  position: relative;
  height: 100vh;

  &__img {
    width: 1061.75vh;
    transition: 0.1s;

    &--absolute {
      position: absolute;
      z-index: 0;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    &--short {
      width: 1022vh;
      overflow: hidden;
    }

    > img {
      display: block;
      width: auto;
      height: 100vh;
      max-width: none;
      transition: 0.5s;
    }
  }
}
</style>
